/* ServiceCard.css */
.service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: #ffA500; /* Orange background color */
    max-width: 300px; /* Adjust the max-width as needed */
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .service-image {
    width: 100%;
    max-height: 200px; /* Adjust the max-height as needed */
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .service-info {
    width: 100%;
  }
  
  .service-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-description {
    color: #d73c3c;
  }
  
  .comprehensive-write-up {
    margin-top: 20px;
    text-align: left;
    position: absolute;
    top: 0;
    right: 100%; /* Adjust the distance from the right side */
    padding-right: 20px; /* Adjust the padding as needed */
  }
  
  .comprehensive-write-up h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .comprehensive-write-up p {
    color: #333;
  }
  
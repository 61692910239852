/* CustomerTicketRequest.css */

.ticket-request-form {
  max-width: 600px; /* Increased for better layout on wider screens */
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ticket-request-form h2 {
  color: #ff8800; /* Orange color */
  margin-bottom: 20px;
  text-align: center;
}

.ticket-request-form form {
  display: flex;
  flex-direction: column;
}

.ticket-request-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.ticket-request-form input,
.ticket-request-form select,
.ticket-request-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  transition: border-color 0.2s ease-in-out;
}

.ticket-request-form input:focus,
.ticket-request-form select:focus,
.ticket-request-form textarea:focus {
  border-color: #ff8800;
  outline: none;
}

/* Buttons */
.ticket-request-form button {
  background-color: #333; /* Black color */
  color: #fff; /* White color */
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.ticket-request-form button:hover {
  background-color: #444;
}

/* Meal Preference Options */
.ticket-request-form .meal-preference-options {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.ticket-request-form .meal-preference-options label {
  display: flex;
  align-items: center;
}

.ticket-request-form .meal-preference-options input {
  margin-right: 5px;
}

/* Responsive Grid for Sections */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.grid-container label {
  display: flex;
  flex-direction: column;
}

/* Ticket Types Inline Display */
.ticket-types {
  display: flex;
  gap: 15px;
}

.ticket-types label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ticket-types input {
  max-width: 80px; /* Small input box for ticket counts */
}

/* Responsive adjustments */
@media (max-width: 500px) {
  .ticket-request-form {
    padding: 15px;
  }

  .ticket-request-form button {
    padding: 8px;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
}

/* Error message styling */
.ticket-request-form .error-message {
  color: red;
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 0.9em;
}

/* src/HomePage.css */

.home-page {
    background-color: white; /* Black background */
    color: black; /* Ash text color */
    padding: 20px;
  }
  
  .header {
    color: #FFA500; /* Orange header text color */
  }
  
  .navigation ul {
    list-style: none;
    padding: 0;
  }
  
  .navigation li {
    display: inline;
    margin-right: 20px;
  }
  
  .main-content {
    margin-top: 20px;
  }
  
  .image-gallery {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .image-gallery img {
    width: 30%; /* Adjust the width as needed */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
/* src/components/Header.css */
.main-header {
    background-color: #968887;
    color: #fff;
    padding: 15px 0;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 300px;
    height: 50px;
    margin-right: 10px;
  }
  
  h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .main-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .main-nav li {
    margin-right: 20px;
  }
  
  .main-nav a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .main-nav a:hover {
    text-decoration: underline;
  }
  
/* AboutUsPage.css */

.about-us-page {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
  }
  
  .about-us-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-content {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-us-quote {
    font-style: italic;
    color: #888;
    margin-top: 20px;
  }
  
  /* Add more styles as needed */
  
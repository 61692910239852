/* src/components/ContactUs.css */

/* Style for the entire ContactUs component */
.contact-us {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Style for the form inside ContactUs */
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  /* Style for form labels */
  .contact-form label {
    margin-bottom: 10px;
  }
  
  /* Style for form inputs and textarea */
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  /* Style for the submit button */
  .contact-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  /* Style for the submit button on hover */
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
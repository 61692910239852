/* src/components/Footer.css */
.main-footer {
    background-color: #706767bd;
    color: #fff;
    padding: 15px 0;
    text-align: center;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .address {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .hotlines, .emails {
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .emails a {
    color: #fff;
    text-decoration: none;
  }
  
  .emails a:hover {
    text-decoration: underline;
  }
  
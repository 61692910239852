/* CustomerPassportIssuing.css */
.customer-passport-issuing-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-passport-issuing-page h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff; /* Blue color */
}

.customer-passport-issuing-page p {
  margin-bottom: 20px;
  color: #333; /* Dark gray color */
}

.customer-passport-issuing-page form {
  display: grid;
  gap: 15px;
}

.customer-passport-issuing-page label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333; /* Dark gray color */
}

.customer-passport-issuing-page input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.customer-passport-issuing-page button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.customer-passport-issuing-page button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}
